export default {
  methods: {
    showStep(step) {
      let form = this.$parent.form ? this.$parent.form : this.form

      switch (step) {
        case "your_job_needs_intro":
        case "your_job_needs":
          return this.fieldStartsWith(form.employment_screener, "yes")
        case "your_living_situation_intro":
        case "your_living_situation_1":
        case "your_living_situation_2":
          return this.fieldStartsWith(form.lettings_screener, "yes")
        default:
          return true
      }
    },
    showField(id) {
      let form = this.$parent.form ? this.$parent.form : this.form

      switch (id) {
        case "employment_warning":
          return (
            this.notLookingForWork() &&
            !this.fieldEquals(form.supports_lettings, true)
          )
        case "lettings_screener":
          return this.fieldEquals(form.supports_lettings, true)
        case "lettings_warning":
          return this.lookingForNeither()
        case "employment_status":
          return this.fieldStartsWith(form.lettings_screener, "yes")
        case "current_hours_per_week":
        case "employment_type":
        case "current_salary":
          return (
            this.fieldStartsWith(form.employment_status, "yes") &&
            this.lookingForHousing()
          )
        case "right_to_work_screener":
          return this.lookingForWork()
        case "right_to_work_or_rent_proof":
          return (
            this.form.right_to_work_or_rent_document_type.value &&
            !this.documentTypeIsNone()
          )
        case "right_to_rent_screener":
          return this.lookingForHousing()
        case "accessibility_requirements":
        case "homeless_hostel_user":
        case "benefits_claimant":
        case "number_of_adult_tenants":
        case "number_of_child_tenants":
        case "living_with_pets":
        case "debt_or_arrears":
          return this.lookingForHousing()
        case "uc_claimant":
          return this.fieldStartsWith(form.benefits_claimant, "yes")
        case "latest_uc_statement":
          return this.fieldStartsWith(form.uc_claimant, "yes")
        case "disability_benefits_claimant":
          return this.fieldStartsWith(form.benefits_claimant, "yes")
        case "debt_amount":
        case "debt_management_plan":
          return this.fieldStartsWith(form.debt_or_arrears, "yes")
        case "open_to_debt_management_plan":
          return (
            this.fieldStartsWith(form.debt_or_arrears, "yes") &&
            (this.fieldEquals(form.debt_management_plan, "No") ||
              this.fieldEquals(form.debt_management_plan, "I am not sure"))
          )
        case "right_to_rent_warning":
          return this.fieldStartsWith(form.right_to_rent_screener, "no")
        case "share_code":
          return (
            this.fieldContains(
              form.right_to_work_or_rent_document_type,
              "Share code"
            ) ||
            this.fieldContains(form.right_to_work_or_rent_document_type, "BRP")
          )
        case "right_to_work_warning":
          return this.fieldStartsWith(form.right_to_work_screener, "no")
        case "no_document_warning":
          return this.documentTypeIsNone()
        case "criminal_record_screener":
          return this.fieldStartsWith(form.criminal_record_trigger, "yes")
        case "on_probation":
          return this.fieldStartsWith(form.criminal_record_trigger, "yes")
        case "probation_officer_name":
          // Only show the probation officer name field if both conditions are met:
          // 1. The user has indicated they have a criminal record
          // 2. The user has indicated they are on probation
          // This prevents collecting unnecessary information when not applicable
          return (
            this.fieldStartsWith(form.criminal_record_trigger, "yes") &&
            this.fieldStartsWith(form.on_probation, "yes")
          )
        case "probation_officer_phone":
          // Only show the probation officer phone field if both conditions are met:
          // 1. The user has indicated they have a criminal record
          // 2. The user has indicated they are on probation
          // This prevents collecting unnecessary information when not applicable
          return (
            this.fieldStartsWith(form.criminal_record_trigger, "yes") &&
            this.fieldStartsWith(form.on_probation, "yes")
          )
        case "drugs_alcohol_free":
          return this.fieldStartsWith(form.drugs_alcohol_screener, "yes")
        case "careers_for_alerts":
          return this.lookingForWork()
        case "priority_career":
          return (
            this.lookingForWork() &&
            this.fieldNotEmpty(form.careers_for_alerts) &&
            !this.fieldContains(form.careers_for_alerts, "none")
          )
        case "careers_warning":
          return false
        case "hours_per_week":
          return this.lookingForWork()
        case "unworkable_shifts":
          return this.lookingForWork()
        case "previous_experience":
          return this.lookingForWork()
        case "unavailable_hours":
          return this.fieldContains(form.unworkable_shifts, "i'm")
        case "start_work_this_week":
          return this.lookingForWork()
        default:
          return true
      }
    },
    documentTypeIsNone() {
      return this.fieldContains(
        this.thisForm.right_to_work_or_rent_document_type,
        "None"
      )
    },
    lookingForWork() {
      return this.fieldStartsWith(this.thisForm.employment_screener, "yes")
    },
    lookingForHousing() {
      return this.fieldStartsWith(this.thisForm.lettings_screener, "yes")
    },
    notLookingForWork() {
      return this.fieldStartsWith(this.thisForm.employment_screener, "no")
    },
    notLookingForHousing() {
      return this.fieldStartsWith(this.thisForm.lettings_screener, "no")
    },
    lookingForNeither() {
      return this.notLookingForWork() && this.notLookingForHousing()
    },
    lookingForBoth() {
      return this.lookingForWork() && this.lookingForHousing()
    },
  },
}
